:root {
  --main-bg-color: #161617;
  --primary-color: #189a46;
  --primary-color-light: #21ba0d;
  --color-white: #fff;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local(Poppins Regular), local(Poppins-Regular), url("https://fonts.gstatic.com/s/poppins/v11/pxiEyp8kv8JHgFVrJJfecnFHGPc.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local(Poppins Bold), local(Poppins-Bold), url("https://fonts.gstatic.com/s/poppins/v11/pxiByp8kv8JHgFVrLCz7Z1xlFd2JQEk.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: local(Poppins Black), local(Poppins-Black), url("https://fonts.gstatic.com/s/poppins/v11/pxiByp8kv8JHgFVrLBT5Z1xlFd2JQEk.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

* {
  margin: 0;
  padding: 0;
}

*, :before, :after {
  box-sizing: inherit;
}

html, body {
  -ms-overflow-style: none;
  scrollbar-width: none;
  height: 100%;
  position: relative;
}

html::-webkit-scrollbar {
  display: none;
}

body::-webkit-scrollbar {
  display: none;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
}

@media only screen and (max-width: 68.75em) {
  html {
    font-size: 50%;
  }
}

@media only screen and (min-width: 112.5em) {
  html {
    font-size: 68.75%;
  }
}

@media only screen and (min-width: 125em) {
  html {
    font-size: 100%;
  }
}

body {
  color: var(--color-white);
  background: linear-gradient(90deg, #efefef 0, var(--main-bg-color) .032rem);
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  background-size: 20%;
  font-family: Poppins, sans-serif;
  font-size: 1.6rem;
  font-weight: 400;
}

@media only screen and (min-width: 31.25em) {
  body {
    background-size: 10%;
  }
}

a {
  text-decoration: none;
}

img {
  object-fit: cover;
  object-position: top center;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: block;
}

ul {
  list-style: none;
}

.main {
  width: 100%;
  height: 100%;
}

.container, .row {
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

@media only screen and (max-width: 56.25em) {
  .row {
    display: grid;
    overflow-x: hidden;
  }
}

section {
  display: grid;
}

.panel {
  box-sizing: border-box;
  will-change: transform;
  flex: none;
  width: 100%;
  padding: 0 1rem;
  position: relative;
}

@media only screen and (min-width: 56.25em) {
  .panel {
    min-height: 100vh;
    padding: 1rem;
  }

  .panel--md {
    width: 50% !important;
  }

  .panel--md .section-inner {
    grid-template-columns: repeat(3, 1fr);
  }

  .panel--lg {
    width: 160% !important;
  }
}

.section-inner {
  grid-gap: 3vmin;
  margin: auto 0;
  padding: 3vmin;
  display: grid;
}

@media only screen and (min-width: 56.25em) {
  .section-inner {
    grid-template: repeat(8, 7vmin) / repeat(8, 1fr);
  }
}

.logo {
  border-radius: 1rem;
  justify-content: center;
  align-items: center;
  width: 8rem;
  height: 8rem;
  padding-left: .25rem;
  display: flex;
  position: fixed;
  top: 1rem;
  left: 7rem;
}

@media only screen and (max-width: 56.25em) {
  .logo {
    position: relative;
    top: 2rem;
    left: 3rem;
  }
}

.logo__emblem {
  width: 100%;
  height: 100%;
}

.heading {
  color: var(--primary-color);
  letter-spacing: 0;
  align-self: end;
  font-size: 3.2rem;
  font-weight: 600;
  line-height: 1;
}

.hero {
  align-items: center;
  display: grid;
  position: relative;
}

@media only screen and (max-width: 56.25em) {
  .hero {
    min-height: 85vmax;
  }
}

@media only screen and (min-width: 56.25em) {
  .hero {
    padding-left: 6rem !important;
  }
}

.intro {
  z-index: 1;
  flex-direction: column;
  grid-area: 4 / 1 / 6 / span 8;
  justify-content: center;
  display: flex;
  position: relative;
}

@media only screen and (max-width: 56.25em) {
  .intro {
    pointer-events: none;
  }

  .intro:after {
    content: "";
    z-index: -1;
    background-color: var(--main-bg-color);
    opacity: .9999;
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
  }
}

@media only screen and (min-width: 56.25em) {
  .intro {
    grid-area: 3 / 1 / 6 / 6;
  }
}

.intro-headline {
  color: var(--primary-color);
  font-size: 4.5rem;
}

@media only screen and (min-width: 56.25em) {
  .intro-headline {
    font-size: 5rem;
  }
}

.intro-description {
  font-size: 1.9rem;
  font-weight: 300;
}

.intro-portrait-wrapper {
  z-index: -1;
  grid-area: 1 / 1 / 7 / span 8;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

@media only screen and (min-width: 56.25em) {
  .intro-portrait-wrapper {
    z-index: 1;
    grid-area: 1 / 6 / 8 / 9;
    height: 100%;
    padding-left: 10%;
    position: relative;
  }
}

.intro-portrait {
  border-radius: 1rem;
  width: 100%;
  height: 100%;
  position: relative;
}

@media only screen and (max-width: 56.25em) {
  .intro-portrait {
    z-index: -2;
    pointer-events: none;
  }

  .intro-portrait:after {
    content: "";
    background-color: var(--main-bg-color);
    opacity: .9999;
    height: 25vmax;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

.intro-portrait--shadow {
  backface-visibility: hidden;
  pointer-events: none;
  background-color: var(--primary-color-light);
  z-index: -1;
  position: absolute;
  inset: 0;
}

@media only screen and (max-width: 56.25em) {
  .intro-portrait--shadow {
    display: none;
  }
}

.intro-portrait > * {
  border-radius: 1rem;
}

.projects {
  position: relative;
}

@media only screen and (max-width: 56.25em) {
  .projects {
    display: block;
  }
}

.projects__item {
  grid-template-columns: auto;
  column-gap: 1%;
  display: grid;
  overflow: hidden;
}

@media only screen and (max-width: 56.25em) {
  .projects__item:not(:last-child) {
    margin-bottom: 3rem;
  }
}

@media only screen and (min-width: 56.25em) {
  .projects__item {
    grid-template-columns: 44% 1fr;
    align-items: end;
    margin-bottom: 0;
    overflow: visible;
  }

  .projects__item--1 {
    grid-area: 1 / 4 / 4 / 7;
  }
}

.projects__item--1 .projects__item-details:before {
  width: 45%;
  left: 0;
  transform: translateX(-25%);
}

@media only screen and (max-width: 56.25em) {
  .projects__item--1 .projects__item-details:before {
    width: .5rem;
    height: 110%;
    top: 0;
    bottom: 0;
    left: 0;
    transform: translate(1.5rem, -5%);
  }
}

@media only screen and (min-width: 56.25em) {
  .projects__item--2 {
    direction: rtl;
    grid-area: 6 / 4 / 8 / 7;
    grid-template-columns: 50% 1fr;
  }

  .projects__item--2 .projects__item__name, .projects__item--2 .projects__item-description {
    text-align: right;
  }
}

.projects__item--2 .projects__item-details {
  padding-top: 2rem;
}

@media only screen and (min-width: 56.25em) {
  .projects__item--2 .projects__item-details {
    direction: ltr;
    justify-items: end;
    padding: 0;
  }
}

.projects__item--2 .projects__item-details:before {
  background: var(--primary-color-light);
  direction: rtl;
  width: 60%;
  right: 0;
  transform: translateX(24%);
}

@media only screen and (max-width: 56.25em) {
  .projects__item--2 .projects__item-details:before {
    width: .5rem;
    height: 110%;
    top: 0;
    bottom: 0;
    left: 0;
    transform: translate(1.5rem, -5%);
  }
}

.projects__item--2 .iphone {
  left: 3rem;
  right: auto;
}

@media only screen and (min-width: 56.25em) {
  .projects__item--3 {
    grid-area: 1 / 7 / 8 / 9;
    grid-template-columns: auto;
    align-self: end;
    margin-left: 4rem;
  }

  .projects__item--3 .projects__item-screen {
    max-width: 40rem;
  }
}

.projects__item--3 .projects__item-details {
  padding-left: 4rem;
}

@media only screen and (min-width: 56.25em) {
  .projects__item--3 .projects__item-details {
    justify-self: end;
    max-width: 40rem;
  }
}

.projects__item--3 .projects__item-details:before {
  width: .5rem;
  height: 110%;
  top: 0;
  bottom: 0;
  left: 0;
  transform: translate(1.5rem, -5%);
}

.projects__item-screen {
  justify-content: center;
  padding-bottom: .5rem;
  font-size: 1.2rem;
  display: flex;
  position: relative;
  overflow: hidden;
}

.projects__item__name {
  color: var(--primary-color);
  letter-spacing: 0;
  font-size: 3.2rem;
  font-weight: 600;
  line-height: 1;
}

.projects__item__name:hover a {
  transform: translateX(.5rem);
}

.projects__item__name a {
  color: currentColor;
  transition: all .3s;
  display: block;
  position: relative;
}

@media only screen and (min-width: 56.25em) {
  .projects__item__name--break {
    display: block;
  }
}

.projects__item-details {
  place-items: center start;
  row-gap: .5rem;
  padding-top: 1rem;
  padding-left: 4rem;
  display: grid;
  position: relative;
}

@media only screen and (min-width: 56.25em) {
  .projects__item-details {
    padding-top: 0;
    padding-left: 0;
  }
}

.projects__item-details:before {
  content: "";
  background: var(--primary-color-light);
  height: .5rem;
  position: absolute;
  top: -1rem;
}

.projects__item-description {
  font-size: 1.5rem;
  font-weight: 300;
}

.projects .section-title {
  pointer-events: none;
}

@media only screen and (min-width: 56.25em) {
  .projects .section-title {
    grid-area: 3 / 1 / 6 / span 4;
    align-self: center;
    position: relative;
    left: 0;
    right: 0;
  }

  .projects .section-title svg {
    height: 38vmin;
  }
}

@media only screen and (max-width: 56.25em) {
  .projects .section-title {
    position: absolute;
    left: 3.5rem;
    right: 2.5rem;
    transform: scale(1.5);
  }
}

.projects .section-description {
  text-align: center;
  margin: 15rem 0 2rem;
  font-size: 2rem;
  font-weight: 300;
}

@media only screen and (min-width: 56.25em) {
  .projects .section-description {
    text-align: left;
    grid-area: 1 / 1 / 2 / span 2;
    align-self: start;
    max-width: 24rem;
    margin: 0;
  }
}

.card {
  background: var(--primary-color);
  border-radius: .5rem;
  min-width: 60%;
  padding: 1rem 1rem .5rem;
}

.card__title {
  color: #f9f9f9;
  margin-bottom: .5rem;
  font-size: 1.4rem;
  font-weight: 300;
  position: relative;
}

.card__title:before {
  content: "";
  background: #d3d3d3;
  width: 95%;
  height: .08rem;
  position: absolute;
  bottom: 0;
  left: 0;
}

.card__content--list {
  align-items: flex-end;
  gap: 0 .5rem;
  display: flex;
}

.card .tech-icon {
  width: 3.5rem;
  height: 3rem;
}

.resume {
  position: relative;
}

@media only screen and (max-width: 56.25em) {
  .resume {
    display: block;
  }
}

.resume__listing {
  margin-top: 19rem;
  display: grid;
}

@media only screen and (min-width: 56.25em) {
  .resume__listing {
    grid-area: 1 / 2 / span 6 / span 3;
    margin: 0;
  }
}

.resume__item {
  flex-direction: column;
  justify-content: center;
  min-height: 10vw;
  padding: 2rem 0;
  font-weight: 300;
  display: flex;
  position: relative;
}

@media only screen and (min-width: 56.25em) {
  .resume__item:first-of-type {
    padding-top: 0;
  }
}

.resume__item:not(:last-child):after {
  content: "";
  background-color: var(--primary-color-light);
  width: 100%;
  height: .1rem;
  position: absolute;
  bottom: 0;
}

.resume__item-company {
  font-size: 2rem;
  font-weight: 500;
}

.resume__download, .resume__download:link, .resume__download:visited {
  font-size: 2rem;
  position: relative;
}

@media only screen and (min-width: 56.25em) {
  .resume__download, .resume__download:link, .resume__download:visited {
    white-space: nowrap;
    grid-row: 8 / 8;
  }
}

.resume__download--icon {
  fill: currentColor;
  width: 2.8rem;
  height: 3.8rem;
}

@media only screen and (min-width: 56.25em) {
  .resume__download--icon {
    position: absolute;
    bottom: 0;
    transform: translateX(-3.7rem);
  }
}

.resume__download--icon path {
  transition: all .3s;
}

.resume__download:hover .resume__download--icon path[data-name="animate"] {
  transform: translateY(10%);
}

.resume .section-title {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

@media only screen and (max-width: 56.25em) {
  .resume .section-title {
    bottom: 71%;
    transform: rotate(90deg)scale(3.8);
  }
}

@media only screen and (min-width: 56.25em) {
  .resume .section-title {
    grid-row: 1 / 9;
    position: relative;
    transform: none;
  }
}

.resume .section-title svg {
  width: 100%;
  height: 100%;
}

.contact .section-title {
  grid-area: 1 / 1 / 6 / span 3;
  align-self: start;
}

@media only screen and (min-width: 56.25em) {
  .contact .section-title svg {
    width: 68vmin;
    height: 50vmin;
  }
}

.contact .heading {
  grid-area: 6 / 1 / 8 / span 3;
  place-self: center start;
}

.contact__listing {
  white-space: nowrap;
  grid-area: 8 / 1 / 9 / 3;
  align-self: end;
  place-items: end start;
  display: grid;
}

.contact__item {
  color: var(--color-white);
  margin-bottom: 1rem;
  font-size: 2rem;
  transition: all .3s;
  position: relative;
}

@media only screen and (min-width: 56.25em) {
  .contact__item {
    margin: 0;
    font-size: 1.6rem;
  }
}

.contact__item--link, .contact__item--link:link, .contact__item--link:visited {
  color: currentColor;
  transition: all .3s;
}

.contact__item--link:focus, .contact__item--link:active, .contact__item--link:link:focus, .contact__item--link:link:active, .contact__item--link:visited:focus, .contact__item--link:visited:active {
  color: var(--primary-color);
  padding-left: 2%;
}

@media not all and (hover: none) {
  .contact__item--link:hover {
    color: var(--primary-color);
    padding-left: 2%;
  }
}

.cursor {
  pointer-events: none;
}

.cursor--main {
  border: .1rem solid var(--color-white);
  background-color: var(--color-white);
  pointer-events: none;
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  transform: translate(-50%, -50%);
}

.cursor--border {
  border: .1rem solid var(--color-white);
  pointer-events: none;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  transition-duration: .1s;
  transition-timing-function: ease-out;
  position: absolute;
  transform: translate(-50%, -50%);
}

.cursor .expand {
  border: .1rem solid red;
  animation: .5s forwards cursorAnim3;
}

.scroll-indicator {
  text-align: center;
  z-index: 1023;
  color: #fff;
  position: absolute;
  bottom: 3.5%;
  left: 8.25%;
}

.scroll-indicator--text {
  padding-bottom: .8rem;
}

@media only screen and (max-width: 56.25em) {
  .scroll-indicator {
    display: none;
    position: relative;
  }
}

.scroll-indicator svg {
  fill: currentColor;
  width: 5.6rem;
  height: 4.8rem;
  margin: 0 .5rem;
  transition: all .3s;
}

.scroll-indicator svg path {
  transition: all .3s;
}

.scroll-indicator svg path[data-name="backward"], .scroll-indicator svg path[data-name="forward"] {
  opacity: 0;
}

.scroll-indicator svg.alternate {
  transform: translateX(0);
}

.scroll-indicator svg.alternate path {
  opacity: 1;
}

.scroll-indicator svg.forward {
  transform: translateX(-20%);
}

.scroll-indicator svg.forward path[data-name="forward"] {
  opacity: 1;
}

.scroll-indicator svg.backward {
  transform: translateX(20%);
}

.scroll-indicator svg.backward path[data-name="backward"] {
  opacity: 1;
}

@keyframes cursorAnim {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(.7);
  }
}

.macbook {
  width: 100%;
  max-width: 80rem;
  margin: 0 auto;
}

.screen {
  background: #000;
  border-radius: 3% 3% .5% .5% / 5%;
  width: 80%;
  margin: 0 auto;
  position: relative;
}

.screen:before {
  content: "";
  border: 2px solid #cacacc;
  border-radius: 3% 3% .5% .5% / 5%;
  padding-top: 67%;
  display: block;
  box-shadow: inset 0 0 0 1px #000c, inset 0 0 1px 2px #ffffff4d;
}

.screen:after {
  content: "";
  border-top: 2px solid #ffffff26;
  width: 99%;
  padding-top: 1%;
  position: absolute;
  bottom: .75%;
  left: .5%;
}

.viewport {
  background: #333;
  margin: 4.3% 3.2%;
  position: absolute;
  inset: 0;
}

.base {
  width: 100%;
  position: relative;
}

.base:before {
  content: "";
  background: linear-gradient(#eaeced, #edeef0 55%, #fff 55%, #8a8b8f 56%, #999ba0 61%, #4b4b4f 84%, #262627 89%, #00000003 98%);
  border-radius: 0 0 10% 10% / 0 0 50% 50%;
  padding-top: 3.3%;
  display: block;
}

.base:after {
  content: "";
  background: linear-gradient(90deg, #00000080, #fffc .5%, #0006 3.3%, #0000 15%, #fffc 50%, #0000 85%, #0006 96.7%, #fffc 99.5%, #00000080 100%);
  width: 100%;
  height: 53%;
  position: absolute;
  top: 0;
}

.notch {
  z-index: 2;
  background: #ddd;
  border-radius: 0 0 7% 7% / 0 0 95% 95%;
  width: 14%;
  margin-top: -3.5%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  box-shadow: inset -5px -1px 3px #0003, inset 5px -1px 3px #0003;
}

.notch:before {
  content: "";
  padding-top: 10%;
  display: block;
}

.macbook .viewport {
  background-position: 0 0;
  background-size: 100%;
  transition: background-position 3s;
  overflow: hidden;
}

.viewport:hover {
  background-position: 0 100%;
}

.macbook .viewport:after {
  content: "";
  background: radial-gradient(circle at 100% 100%, #0000 75%, #ffffff0d 75%, #0000);
  position: absolute;
  inset: 0;
}

.mobile {
  z-index: 200;
  transform-origin: bottom;
  background-position: 0 0;
  background-repeat: no-repeat, no-repeat;
  background-size: 100%;
  border: .3rem solid #111;
  border-radius: .5em;
  width: 7.1rem;
  height: 13.7rem;
  transition: background-position 3s;
  display: block;
  position: absolute;
  bottom: 1.7rem;
  right: 3rem;
}

@media only screen and (min-width: 56.25em) {
  .mobile {
    width: 5rem;
    height: 9.3rem;
  }
}

.mobile:hover {
  background-position: 0 100%;
}

/*# sourceMappingURL=index.24f998f2.css.map */
