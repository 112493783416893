:root {
	--main-bg-color: #161617;
	--primary-color: #189a46;
	--primary-color-light: #21ba0d;
	--color-white: #ffffff;
}

$bp-largest: 75em; // 1200px
$bp-large: 68.75em; // 1100px
$bp-medium: 56.25em; // 900px
$bp-small: 37.5em; // 600px
$bp-smallest: 31.25em; // 500px

@font-face {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: local("Poppins Regular"), local("Poppins-Regular"),
		url(https://fonts.gstatic.com/s/poppins/v11/pxiEyp8kv8JHgFVrJJfecnFHGPc.woff2)
			format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
		U+FEFF, U+FFFD;
}

@font-face {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: local("Poppins Bold"), local("Poppins-Bold"),
		url(https://fonts.gstatic.com/s/poppins/v11/pxiByp8kv8JHgFVrLCz7Z1xlFd2JQEk.woff2)
			format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
		U+FEFF, U+FFFD;
}

@font-face {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 900;
	font-display: swap;
	src: local("Poppins Black"), local("Poppins-Black"),
		url(https://fonts.gstatic.com/s/poppins/v11/pxiByp8kv8JHgFVrLBT5Z1xlFd2JQEk.woff2)
			format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
		U+FEFF, U+FFFD;
}

* {
	margin: 0;
	padding: 0;
}

*,
*::before,
*::after {
	box-sizing: inherit;
}

html,
body {
	position: relative;
	height: 100%;
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}

html::-webkit-scrollbar,
body::-webkit-scrollbar {
	display: none;
}

html {
	box-sizing: border-box;
	font-size: 62.5%; // 1rem = 10px, 10px/16px = 62.5%
	@media only screen and (max-width: $bp-large) {
		font-size: 50%; //1rem = 8px; 8px/16px = 50%;
	}
	@media only screen and (min-width: 112.5em) {
		font-size: 68.75%; //1rem = 19px; 19px/16px = 118.75%;
	}
	@media only screen and (min-width: 125em) {
		font-size: 100%; //1rem = 19px; 19px/16px = 118.75%;
	}
}

body {
	font-family: "Poppins", sans-serif;
	font-weight: 400;
	// line-height: 1.6;
	color: var(--color-white);
	background: linear-gradient(90deg, #efefef 0, var(--main-bg-color) 0.032rem);
	-ms-touch-action: pan-y;
	touch-action: pan-y;
	font-size: 1.6rem;
	background-size: 20%;

	@media only screen and (min-width: $bp-smallest) {
		background-size: 10%;
	}
}

a {
	text-decoration: none;
}

img {
	width: 100%;
	height: 100%;
	display: block;
	margin: 0 auto;
	object-fit: cover;
	object-position: top center;
}

ul {
	list-style: none;
}