////////////////////////////////////////
// LOGO
.logo {
	width: 8rem;
	height: 8rem;
	// background: var(--primary-color-light);
	position: fixed;
	// position: absolute;
	top: 1rem;
	left: 7rem;
	padding-left: 0.25rem;
	border-radius: 1rem;
	display: flex;
	align-items: center;
	justify-content: center;

	@media only screen and (max-width: $bp-medium) {
		position: relative;
		top: 2rem;
		left: 3rem;
	}

	&__emblem {
		width: 100%;
		height: 100%;
	}
}

////////////////////////////////////////
// HEADING

.heading {
	color: var(--primary-color);
	font-size: 3.2rem;
	font-weight: 600;
	letter-spacing: 0;
	line-height: 1;
	align-self: end;
}

////////////////////////////////////////
// HERO

.hero {
	position: relative;
	display: grid;
	align-items: center;

	@media only screen and (max-width: $bp-medium) {
		min-height: 85vmax;
	}

	@media only screen and (min-width: $bp-medium) {
		padding-left: 6rem !important;
	}
}

////////////////////////////////////////
// INTRO

.intro {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	grid-column: 1 / span 8;
	grid-row: 4 / 6;
	z-index: 1;

	@media only screen and (max-width: $bp-medium) {
		pointer-events: none;
		&::after {
			content: "";
			position: absolute;
			z-index: -1;
			height: 100%;
			left: 0;
			right: 0;

			background-color: var(--main-bg-color);
			opacity: 0.9999;
		}
	}

	@media only screen and (min-width: $bp-medium) {
		grid-row: 3 / 6;
		grid-column: 1 / 6;
	}

	&-headline {
		color: var(--primary-color);
		font-size: 4.5rem;

		@media only screen and (min-width: $bp-medium) {
			font-size: 5rem;
		}
	}

	&-description {
		font-size: 1.9rem;
		font-weight: 300;
	}

	&-portrait-wrapper {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		z-index: -1;
		grid-row: 1 / 7;
		grid-column: 1 / span 8;

		@media only screen and (min-width: $bp-medium) {
			height: 100%;
			z-index: 1;
			position: relative;
			padding-left: 10%;
			// padding-top: 10vmin;
			grid-row: 1 / 8;
			grid-column: 6 / 9;
		}
	}

	&-portrait {
		position: relative;
		height: 100%;
		width: 100%;
		border-radius: 1rem;
		@media only screen and (max-width: $bp-medium) {
			z-index: -2;
			pointer-events: none;
			&::after {
				content: "";
				position: absolute;
				left: 0;
				right: 0;
				bottom: 0;
				height: 25vmax;
				background-color: var(--main-bg-color);
				opacity: 0.9999;
			}
		}

		&--shadow {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			backface-visibility: hidden;
			pointer-events: none;
			background-color: var(--primary-color-light);
			z-index: -1;

			@media only screen and (max-width: $bp-medium) {
				display: none;
			}
		}

		& > * {
			border-radius: 1rem;
		}
	}
}

////////////////////////////////////////
// PROJECTS
.projects {
	position: relative;
	@media only screen and (max-width: $bp-medium) {
		display: block;
	}
	&__item {
		display: grid;
		grid-template-columns: auto;
		column-gap: 1%;
		overflow: hidden;
		@media only screen and (max-width: $bp-medium) {
			&:not(:last-child) {
				margin-bottom: 3rem;
			}
		}

		@media only screen and (min-width: $bp-medium) {
			overflow: visible;
			align-items: end;
			grid-template-columns: 44% 1fr;
			margin-bottom: 0;
		}

		&--1 {
			/* background-color: orchid; */
			// grid-column: 1 / 6;
			// grid-row: 1 / 3;
			@media only screen and (min-width: $bp-medium) {
				grid-row: 1 / 4;
				grid-column: 4 / 7;
			}

			.projects__item-details::before {
				width: 45%;
				left: 0;
				transform: translateX(-25%);

				@media only screen and (max-width: $bp-medium) {
					width: 0.5rem;
					height: 110%;
					top: 0;
					bottom: 0;
					left: 0;
					transform: translate(1.5rem, -5%);
				}
			}
		}

		&--2 {
			// grid-column: 1 / 6;
			// grid-row: 4 / 7;
			@media only screen and (min-width: $bp-medium) {
				grid-template-columns: 50% 1fr;
				grid-row: 6 / 8;
				grid-column: 4 / 7;
				direction: rtl;
				// overflow: hidden !important;

				.projects__item__name, .projects__item-description {
					text-align: right;
				}
			}
	
			.projects__item-details {
				padding-top: 2rem;
				@media only screen and (min-width: $bp-medium) {
					padding: 0;
					justify-items: end;
					direction: ltr;
				}

				&::before {
					right: 0;
					transform: translateX(24%);
					background: var(--primary-color-light);
					direction: rtl;
					width: 60%;

					@media only screen and (max-width: $bp-medium) {
						width: 0.5rem;
					height: 110%;
					top: 0;
					bottom: 0;
					left: 0;
					transform: translate(1.5rem, -5%);
					}
				}
			}

			.iphone {
				left: 3rem;
				right: auto;
			}
		}

		&--3 {
			@media only screen and (min-width: $bp-medium) {
				grid-template-columns: auto;
				// align-items: center;
				align-self: end;
				margin-left: 4rem;
				grid-row: 1 / 8;
				grid-column: 7 / 9;
				.projects__item-screen {
					max-width: 40rem;
				}
			}

			.projects__item-details {
				padding-left: 4rem;
				@media only screen and (min-width: $bp-medium) {
					justify-self: end;
					max-width: 40rem;
				}

				&::before {
					width: 0.5rem;
					height: 110%;
					top: 0;
					bottom: 0;
					left: 0;
					transform: translate(1.5rem, -5%);
				}
			}
		}

		&-screen {
			position: relative;
			display: flex;
			justify-content: center;
			overflow: hidden;
			font-size: 1.2rem;
			padding-bottom: 0.5rem;

			// @media only screen and (max-width: $bp-medium) {
			// 	width: 100%;
			// 	// max-width: 40rem;
			// }
		}

		&__name {
			color: var(--primary-color);
			font-size: 3.2rem;
			font-weight: 600;
			letter-spacing: 0;
			line-height: 1;

			&:hover {
				a {
					transform: translateX(0.5rem);
				}
			}

			a {
				display: block;
				position: relative;
				color: currentColor;
				transition: 0.3s ease;
			}

			&--break {
				@media only screen and (min-width: $bp-medium) {
					display: block;
				}
			}
		}

		&-details {
			position: relative;
			display: grid;
			align-items: center;
			justify-items: start;
			row-gap: 0.5rem;
			padding-top: 1rem;
			padding-left: 4rem;

			@media only screen and (min-width: $bp-medium) {
				padding-left: 0;
				padding-top: 0;
			}

			&::before {
				content: "";
				position: absolute;
				top: -1rem;
				height: 0.5rem;
				background: var(--primary-color-light);
			}
		}

		&-description {
			font-size: 1.5rem;
			font-weight: 300;
		}
	}

	.section-title {
		pointer-events: none;

		@media only screen and (min-width: $bp-medium) {
			position: relative;
			align-self: center;
			left: 0;
			right: 0;
			grid-row: 3 / 6;
			grid-column: 1 / span 4;

			svg {
				// width: 60vmax;
				height: 38vmin;
			}
		}
		@media only screen and (max-width: $bp-medium) {
			position: absolute;
			transform: scale(1.5);
			left: 3.5rem;
			right: 2.5rem;
		}
	}

	.section-description {
		font-size: 2rem;
		font-weight: 300;
		text-align: center;
		margin: 15rem 0 2rem;

		@media only screen and (min-width: $bp-medium) {
			text-align: left;
			max-width: 24rem;
			align-self: start;
			grid-row: 1 / 2;
			grid-column: 1 / span 2;
			margin: 0;
			// display: none;
		}
	}
}

////////////////////////////////////////
// PROJECT CARD
.card {
	background: var(--primary-color);
	border-radius: 0.5rem;
	padding: 1rem 1rem 0.5rem;
	min-width: 60%;

	&__title {
		position: relative;
		color: #f9f9f9;
		font-size: 1.4rem;
		font-weight: 300;
		margin-bottom: 0.5rem;

		&::before {
			content: "";
			position: absolute;
			bottom: 0;
			height: 0.08rem;
			// height: 0.025rem;
			left: 0;
			width: 95%;
			background: #d3d3d3;
		}
	}

	&__content--list {
		display: flex;
		align-items: flex-end;
		gap: 0 0.5rem;
	}

	.tech-icon {
		width: 3.5rem;
		height: 3rem;
	}
}

////////////////////////////////////////
// RESUME
.resume {
	position: relative;
	@media only screen and (max-width: $bp-medium) {
		display: block;
		// padding-top: 1rem;
	}

	&__listing {
		display: grid;
		// margin-top: 15rem;
		margin-top: 19rem;

		@media only screen and (min-width: $bp-medium) {
			margin: 0;
			grid-column: 2 / span 3;
			grid-row: 1 / span 6;
		}
	}

	&__item {
		position: relative;
		font-weight: 300;
		padding: 2rem 0;
		min-height: 10vw;
		display: flex;
		flex-direction: column;
		justify-content: center;

		&:first-of-type {
			@media only screen and (min-width: $bp-medium) {
				padding-top: 0;
			}
		}

		&:not(:last-child)::after {
			content: "";
			position: absolute;
			bottom: 0;
			height: 0.1rem;
			width: 100%;
			background-color: var(--primary-color-light);
		}

		&-company {
			font-weight: 500;
			font-size: 2rem;
		}
	}

	&__download {
		&,
		&:link,
		&:visited {
			position: relative;
			font-size: 2rem;

			@media only screen and (min-width: $bp-medium) {
				white-space: nowrap;
				grid-row: 8 / 8;
			}
		}
		&--icon {
			width: 2.8rem;
			height: 3.8rem;
			fill: currentColor;
			@media only screen and (min-width: $bp-medium) {
				position: absolute;
				bottom: 0;
				transform: translateX(-3.7rem);
			}

			& path {
				transition: all 0.3s;
			}
		}

		&:hover &--icon path[data-name="animate"] {
			transform: translateY(10%);
		}
	}

	.section-title {
		pointer-events: none;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		@media only screen and (max-width: $bp-medium) {
			bottom: 71%;
			transform: rotate(90deg) scale(3.8);
		}

		@media only screen and (min-width: $bp-medium) {
			position: relative;
			transform: none;
			grid-row: 1 / 9;
		}

		svg {
			height: 100%;
			width: 100%;
		}
	}
}

////////////////////////////////////////
// CONTACT

.contact {
	.section-title {
		// grid-column: 1 / span 3;
		grid-row: 1 / 6;
		grid-column: 1 / span 3;
		align-self: start;

		svg {
			@media only screen and (min-width: $bp-medium) {
				width: 68vmin;
				height: 50vmin;
			}
		}
	}

	.heading {
		grid-row: 6 / 8;
		grid-column: 1 / span 3;
		justify-self: start;
		align-self: center;
	}

	&__listing {
		grid-row: 8 / 9;
		grid-column: 1 / 3;
		display: grid;
		align-self: end;
		justify-items: start;
		align-items: end;
		white-space: nowrap;
	}

	&__item {
		position: relative;
		color: var(--color-white);
		transition: 0.3s ease;
		margin-bottom: 1rem;
		font-size: 2rem;
		@media only screen and (min-width: $bp-medium) {
			font-size: 1.6rem;
			margin: 0;
		}

		&--link {
			&,
			&:link,
			&:visited {
				color: currentColor;
				transition: 0.3s ease;
				&:focus,
				&:active {
					color: var(--primary-color);
					padding-left: 2%;
				}
			}

			@media not all and (hover: none) {
				&:hover {
					color: var(--primary-color);
					padding-left: 2%;
				}
			}
		}
	}
}

////////////////////////////////////////
// CONTACT

.cursor {
	pointer-events: none;

	&--main {
		width: 1.5rem;
		height: 1.5rem;
		border: 0.1rem solid var(--color-white);
		background-color: var(--color-white);
		position: absolute;
		transform: translate(-50%, -50%);
		border-radius: 50%;
		pointer-events: none;
		/* transition-duration: 200ms; */
		/* transition-timing-function: ease-out; */
	}

	&--border {
		position: absolute;
		width: 3rem;
		height: 3rem;
		border-radius: 50%;
		border: 0.1rem solid var(--color-white);
		transform: translate(-50%, -50%);
		/* left: 0;
    right: 0;
    top: 0;
    bottom: 0; */
		transition-duration: 100ms;
		transition-timing-function: ease-out;
		pointer-events: none;
	}

	.expand {
		animation: cursorAnim3 0.5s forwards;
		border: 0.1rem solid red;
	}
}

///////
// Scroll Indicator
.scroll-indicator {
	position: absolute;
	left: 8.25%;
	bottom: 3.5%;
	text-align: center;
	z-index: 1023;
	color: #ffffff;
	&--text {
		padding-bottom: 0.8rem;
	}

	@media only screen and (max-width: $bp-medium) {
		position: relative;
		display: none;
	}

	svg {
		width: 5.6rem;
		height: 4.8rem;
		margin: 0 0.5rem;
		fill: currentColor;
		transition: all 0.3s ease;

		path {
			transition: 0.3s ease;
			&[data-name="backward"],
			&[data-name="forward"] {
				opacity: 0;
			}
		}

		&.alternate {
			transform: translateX(0);
			path {
				opacity: 1;
			}
		}

		&.forward {
			transform: translateX(-20%);

			path[data-name="forward"] {
				opacity: 1;
			}
		}

		&.backward {
			transform: translateX(20%);

			path[data-name="backward"] {
				opacity: 1;
			}
		}
	}
}

@keyframes cursorAnim {
	from {
		transform: scale(1);
	}
	to {
		transform: scale(0.7);
	}
}
