.main {
	height: 100%;
	width: 100%;

	/* overflow: auto; */
	/* padding: 0 60px; */
}

.container {
	margin-left: auto;
	margin-right: auto;
	display: flex;
}

.row {
	margin-left: auto;
	margin-right: auto;
	display: flex;
	@media only screen and (max-width: $bp-medium) {
		overflow-x: hidden;
		display: grid;
	}
}

section {
	display: grid;
}

.panel {
	width: 100%;
	/* font-weight: 600; */
	/* font-size: 1.5em; */
	position: relative;
	box-sizing: border-box;
	will-change: transform;
	flex: 0 0 auto;
	flex-shrink: 0;
	padding: 0 1rem;

	@media only screen and (min-width: $bp-medium) {
		padding: 1rem;
		min-height: 100vh;
		&--md {
			width: 50% !important;

			.section-inner {
				grid-template-columns: repeat(3, 1fr);
			}
		}
	}
}

.panel--lg {
	@media only screen and (min-width: $bp-medium) {
		width: 160% !important;
	}
}

.section-inner {
	display: grid;
	// grid-gap: 2.3rem;
	// padding: 1.5rem;
	margin: auto 0;
	grid-gap: 3vmin;
	padding: 3vmin;

	@media only screen and (min-width: $bp-medium) {
		// grid-template: repeat(7, 5vw) / repeat(8, 1fr);
		grid-template: repeat(8, 7vmin) / repeat(8, 1fr);
	}
}
